import { SC } from 'constants/ApiConstant'
import SimpleCrypto from 'simple-crypto-js'

const simpleCryto = new SimpleCrypto(SC)
let cf = {}
try {
  cf = simpleCryto.decrypt(sessionStorage.getItem('cf'))
} catch (error) {
  console.log(error)
}

const envPicker = (key) => process.env[key] || cf[key]

let thirdPartyToken = envPicker('REACT_APP_API_THIRD_PARTY_TOKEN')
const apiEndPoint = envPicker('REACT_APP_API_ENDPOINT_URL')
const CLIENT_API_BASE_URL = envPicker('REACT_APP_CLIENT_API_BASE_URL')
const IS_ENCRYPTED = envPicker('REACT_APP_ENCRYPTION_MODE')
const ENCRYPTION_VALUE = envPicker('REACT_APP_ENCRYPTION_VALUE')
const ENCRYPTION_KEY = envPicker('REACT_APP_ENCRYPTION_KEY')
const IS_JIRAN = envPicker('REACT_APP_IS_JIRAN')

const getEnv = () => {
  return {
    API_ENDPOINT_URL: apiEndPoint,
    THIRD_PARTY_TOKEN: thirdPartyToken,
    CLIENT_API_BASE_URL,
    IS_ENCRYPTED,
    ENCRYPTION_VALUE,
    ENCRYPTION_KEY,
    IS_JIRAN: IS_JIRAN === "true"
  }
}

export const env = getEnv()
var REACT_APP_API_ENDPOINT_URL = "https://prod-backend-surfapi.surf-admin.link/api/v1/admin/";